/* Modal background overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  animation: fadeIn 0.3s forwards;
}

/* Modal content box */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  max-width: 100%;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-50px);
  opacity: 0;
  animation: slideDown 0.4s forwards;
}

/* Modal fade-in effect */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Modal slide-down effect */
@keyframes slideDown {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Button styling inside modal */
.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #003a75;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #072f55;
}
