.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  padding: 2px 50px;
  background-color: #0958b2;
  color: white;
}

.App-header .logo {
  flex: 1;
  text-align: left;
}

.App-logo {
  width: 80px;
  height: auto;
}

.App-header .nav-links {
  flex: 2;
  text-align: right;
}

.App-header .nav-links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.Hero {
  background: radial-gradient(circle, #0958b2, #3caad1);
  padding: 80px 20px;
  color: white;
}

.Hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  flex-wrap: wrap;
}

.Hero-content .text-content {
  max-width: 50%;
  text-align: left;
}

.Hero-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.Hero-content p {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.cta-buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.cta-buttons button {
  margin-right: 15px;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  background-color: #003a75;
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-buttons button:hover {
  background-color: #072f55;
}

.screenshots {
  max-width: 45%;
  text-align: center;
}

.phone-image {
  max-width: 100%;
  border-radius: 10px;
}

.Signup {
  background-color: #fff;
  padding: 60px 20px;
  text-align: center;
}

.Signup h2 {
  color: #0958b2;
  font-size: 2em;
  margin-bottom: 20px;
}

.Signup form {
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Signup input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #0958b2;
  border-radius: 5px;
  font-size: 16px;
}

.Signup button {
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  background-color: #003a75;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Signup button:hover {
  background-color: #072f55;
}

footer {
  background: radial-gradient(circle, #0958b2, #3caad1);
  padding: 40px;
  color: white;
}

footer nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 768px) {
  .App-header {
    flex-direction: row-reverse;
    justify-content: center;
    padding: 10px 20px;
    height: auto;
  }

  .App-header .logo {
    flex: 0;
    order: 2;
    text-align: right;
  }

  .App-header .nav-links {
    flex: 1;
    order: 1;
    text-align: center;
    margin-left: 0;
  }

  .App-header .nav-links a {
    margin: 0 10px;
    font-size: 16px;
  }

  .Hero-content {
    flex-direction: column;
    text-align: center;
  }

  .Hero-content .text-content {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .screenshots {
    max-width: 80%;
    margin: 20px 0;
  }

  .phone-image {
    max-width: 100%;
    margin: 20px 0;
  }

  .cta-buttons {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .Hero-content h1 {
    font-size: 2.5em;
  }

  .Hero-content p {
    font-size: 1.2em;
  }

  .cta-buttons button {
    width: 100%;
    margin-bottom: 15px;
  }

  .Signup h2 {
    font-size: 1.5em;
  }

  .Signup input {
    width: 90%;
  }

  .Signup button {
    width: 90%;
  }
}
.PrivacyPolicy {
  max-width: 800px;
  margin: 40px auto;
  text-align: left;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
  color: #333;
}

.PrivacyPolicy h2 {
  font-size: 2em;
  color: #003a75;
  margin-bottom: 20px;
}

.PrivacyPolicy p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

.PrivacyPolicy p strong {
  font-weight: bold;
  color: #0958b2;
}

.PrivacyPolicy p a {
  color: #003a75;
  text-decoration: none;
  border-bottom: 1px solid #003a75;
  transition: color 0.3s ease;
}

.PrivacyPolicy p a:hover {
  color: #072f55;
}

@media (max-width: 768px) {
  .PrivacyPolicy {
    padding: 15px;
    margin: 20px 10px;
  }

  .PrivacyPolicy h2 {
    font-size: 1.75em;
  }

  .PrivacyPolicy p {
    font-size: 1em;
  }
}
.TermsOfService {
  max-width: 800px;
  margin: 40px auto;
  text-align: left;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
  color: #333;
}

.TermsOfService h2 {
  font-size: 2em;
  color: #003a75;
  margin-bottom: 20px;
}

.TermsOfService p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

.TermsOfService p strong {
  font-weight: bold;
  color: #0958b2;
}

.TermsOfService p a {
  color: #003a75;
  text-decoration: none;
  border-bottom: 1px solid #003a75;
  transition: color 0.3s ease;
}

.TermsOfService p a:hover {
  color: #072f55;
}

@media (max-width: 768px) {
  .TermsOfService {
    padding: 15px;
    margin: 20px 10px;
  }

  .TermsOfService h2 {
    font-size: 1.75em;
  }

  .TermsOfService p {
    font-size: 1em;
  }
}
.CommunityGuidelines {
  max-width: 800px;
  margin: 40px auto;
  text-align: left;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
  color: #333;
}

.CommunityGuidelines h2 {
  font-size: 2em;
  color: #003a75;
  margin-bottom: 20px;
}

.CommunityGuidelines p {
  margin-bottom: 20px;
  font-size: 1.1em;
  color: #555;
}

.CommunityGuidelines p strong {
  font-weight: bold;
  color: #0958b2;
}

.CommunityGuidelines h3 {
  font-size: 1.5em;
  color: #003a75;
  margin-bottom: 15px;
}

.CommunityGuidelines ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.CommunityGuidelines ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

footer {
  background-color: #003a75;
  padding: 20px;
  color: white;
}

footer p {
  margin: 0;
}

footer nav {
  margin-top: 10px;
}

footer nav a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 768px) {
  .CommunityGuidelines {
    padding: 15px;
    margin: 20px 10px;
  }

  .CommunityGuidelines h2 {
    font-size: 1.75em;
  }

  .CommunityGuidelines p {
    font-size: 1em;
  }

  .CommunityGuidelines h3 {
    font-size: 1.4em;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.checkbox label {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.checkbox input[type="checkbox"]:checked {
  background-color: #003a75;
  border: 2px solid #003a75;
}

.checkbox input[type="checkbox"]:focus {
  outline: 2px solid #0958b2;
}
.platform-buttons {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.platform-buttons button {
  padding: 10px 20px;
  margin: 0 10px;
  border: 1px solid #0958b2;
  border-radius: 5px;
  background-color: white;
  color: #0958b2;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.platform-buttons button:hover {
  background-color: #003a75;
  color: white;
}

.platform-buttons button.active {
  background-color: #003a75;
  color: white;
  border-color: #003a75;
}
/* General styles for the form */
.Support form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

.Support input,
.Support textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid #0958b2;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.Support input:focus,
.Support textarea:focus {
  border-color: #003a75; /* Darker blue on focus */
}

.Support textarea {
  resize: none;
  height: 150px;
}

.Support button {
  padding: 12px 20px;
  background-color: #003a75;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.Support button:hover {
  background-color: #072f55; /* Slightly darker on hover */
}

/* Header styling for form title */
.Support h2 {
  text-align: center;
  color: #0958b2;
  font-size: 2em;
  margin-bottom: 20px;
}

/* Centered layout */
.Support {
  padding: 50px 20px;
  background-color: #f4f4f4; /* Light background for the section */
}

/* Optional: Style for the form wrapper to add a shadow */
.Support {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
  max-width: 600px;
  margin: auto;
}
/* General styles for form inputs */
.Support input,
.Support textarea,
.Support select {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid #0958b2;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
  appearance: none; /* Remove default arrow for select on some browsers */
}

/* Specific styling for select field */
.Support select {
  background-color: white;
  cursor: pointer;
}

/* Focus state for all form fields */
.Support input:focus,
.Support textarea:focus,
.Support select:focus {
  border-color: #003a75; /* Darker blue when focused */
}

/* Button styling */
.Support button {
  width: 100%;
  padding: 15px;
  background-color: #003a75;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Support button:hover {
  background-color: #072f55; /* Darker blue on hover */
}
